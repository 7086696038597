export const textHead = {
    class: "mb-8",
    gap: 8,
    as: "h6",
    bold: true,
};

export const inputSelect = {
    componentName: "InputSelect",
    lg: 6,
    outsideTitle: false,
    wide: true,
    small: true,
};

export const inputStandard = {
    componentName: "InputStandard",
    small: true,
    wide: true,
    lg: 6,
};

export const inputDate = {
    title: "MM/DD/YYYY",
    componentName: "InputDate",
    icon: "new_calendar",
    clearable: true,
    small: true,
    wide: true,
    lg: 6,
};

export const addressLookup = {
    componentName: "AddressLookup",
    wide: true,
    small: true,
    create_only: true,
    lg: 12,
};

export const multipleTag = {
    componentName: "MultipleTag",
    small: true,
    stringEmail: true,
    validation: false,
    wide: true,
    lg: 6,
};

export const textArea = {
    componentName: "TextareaStandard",
    maxLength: 20000,
};

export const searchRecord = {
    componentName: "SearchRecord",
    required: true,
    small: true,
    lg: 12,
};

export const applianceSearch = {
    componentName: "ApplianceSearch",
    wide: true,
    small: true,
    lg: 6,
};

export const card = {
    componentName: "Card",
    ultrasmall: true,
};

export const standardCustomButton = {
    componentName: "CustomButton",
    wide: true,
    ultrasmall: true,
};

export const transparentCustomButton = {
    ...standardCustomButton,
    transparent: true,
};

export const translucentCustomButton = {
    ...standardCustomButton,
    translucent: true,
};

export const whiteCustomButton = {
    ...standardCustomButton,
    type: "white",
};

export const fullWidthCol = {
    lg: 12,
};
