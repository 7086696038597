<template>
    <div class="custom-input-date" :class="{ _small: small }">
        <TextHead as="h6" :text="title" :bold="bold" v-if="outsideTitle && title" class="mb-10" />
        <VueDatePicker
            v-model="currentDate"
            :range="range"
            :multi-calendars="multi"
            :multi-calendars-solo="multi"
            :inline="inline"
            auto-apply
            :format="format"
            :disabled="isDisabled"
            :enable-time-picker="false"
            :is-24="false"
            :clearable="clearable"
            menu-class-name="custom-input__calendar"
            :placeholder="(!outsideTitle && title) || placeholder || ' '"
        >
            <template #input-icon>
                <Icon :icon="computedIcon" />
            </template>
        </VueDatePicker>
    </div>
</template>

<script>
import { defineComponent, computed } from "vue";

import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import { TextHead } from "../TextHead";
import { Icon } from "../Icon";
import { useI18n } from "vue-i18n";

const InputDate = defineComponent({
    name: "InputDate",
    props: {
        title: {
            type: String,
            default: null,
        },
        data: {
            type: [String, Date, Array, Number],
            required: true,
            default: "",
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        range: {
            type: Boolean,
        },
        inline: {
            type: Boolean,
        },
        multi: {
            type: Boolean,
        },
        outsideTitle: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
        },
        bold: {
            type: Boolean,
        },
        isArchived: {
            type: Boolean,
        },
        small: {
            type: Boolean,
        },
        icon: {
            type: String,
        },
    },
    emits: ["update:data", "updateData"],
    components: {
        VueDatePicker,
        TextHead,
        Icon,
    },
    setup(props, { emit }) {
        const i18n = useI18n();

        const currentDate = computed({
            get() {
                return props.data;
            },
            set(data) {
                let date = "";
                if (Array.isArray(data)) {
                    date = data.map((el) => new Date(el).toISOString());
                } else if (data) {
                    date = new Date(data).toISOString();
                }
                emit("update:data", date);
                emit("updateData", date);
            },
        });
        const isDisabled = computed(() => props.disabled || props.isArchived);

        const format = (date) => {
            if (Array.isArray(date)) {
                const dates = date.map((el) => i18n.d(el, "contest", { locale: "en-GB" }));

                return dates.join(" - ");
            } else if (date) {
                return i18n.d(date, "contest", { locale: "en-GB" });
            }
            return "";
        };

        const computedIcon = computed(() => (props.clearable && currentDate.value ? null : props.icon));

        return { currentDate, isDisabled, computedIcon, format };
    },
});

export default InputDate;
</script>

<style lang="scss">
.custom {
    @import "./assets/input-date";
}
</style>
