export const add_page = `<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 21.25V2.75H15.75V5.4C15.75 6.14558 16.3544 6.75 17.1 6.75H19.75V21.25H5.25ZM21.2489 5.69514C21.2496 5.71289 21.25 5.73069 21.25 5.74853V21.4C21.25 22.1456 20.6456 22.75 19.9 22.75H5.1C4.35441 22.75 3.75 22.1456 3.75 21.4V2.6C3.75 1.85441 4.35442 1.25 5.1 1.25H16.7515C16.7693 1.25 16.7871 1.25035 16.8049 1.25106C16.821 1.25035 16.8372 1.25 16.8536 1.25C17.1462 1.25 17.4269 1.36627 17.6339 1.57322L17.7061 1.64541L20.8546 4.79393C20.8548 4.79416 20.855 4.79439 20.8553 4.79461L20.9268 4.86612C21.1337 5.07307 21.25 5.35377 21.25 5.64645C21.25 5.66276 21.2496 5.679 21.2489 5.69514ZM13.25 9C13.25 8.58579 12.9142 8.25 12.5 8.25C12.0858 8.25 11.75 8.58579 11.75 9V11.25H9.5C9.08579 11.25 8.75 11.5858 8.75 12C8.75 12.4142 9.08579 12.75 9.5 12.75H11.75V15C11.75 15.4142 12.0858 15.75 12.5 15.75C12.9142 15.75 13.25 15.4142 13.25 15V12.75H15.5C15.9142 12.75 16.25 12.4142 16.25 12C16.25 11.5858 15.9142 11.25 15.5 11.25H13.25V9Z" fill="white"/></svg>`;

export const favorite = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.947 9.17901C21.8842 8.99388 21.7685 8.83121 21.6142 8.71107C21.46 8.59094 21.2739 8.51861 21.079 8.50301L15.378 8.05001L12.911 2.58901C12.8325 2.41313 12.7047 2.26374 12.5431 2.15887C12.3815 2.05401 12.193 1.99815 12.0004 1.99805C11.8077 1.99794 11.6192 2.05359 11.4575 2.15828C11.2957 2.26296 11.1678 2.41221 11.089 2.58801L8.62203 8.05001L2.92103 8.50301C2.72948 8.51819 2.54636 8.58822 2.39358 8.70475C2.2408 8.82127 2.12482 8.97934 2.05952 9.16004C1.99422 9.34075 1.98236 9.53645 2.02537 9.72372C2.06838 9.91099 2.16443 10.0819 2.30203 10.216L6.51503 14.323L5.02503 20.775C4.97978 20.9703 4.99428 21.1747 5.06665 21.3617C5.13901 21.5486 5.26589 21.7095 5.43083 21.8235C5.59577 21.9374 5.79115 21.9991 5.99161 22.0007C6.19208 22.0022 6.38837 21.9434 6.55503 21.832L12 18.202L17.445 21.832C17.6154 21.9451 17.8162 22.0033 18.0207 21.9988C18.2251 21.9944 18.4232 21.9274 18.5884 21.8069C18.7536 21.6865 18.878 21.5183 18.9448 21.3251C19.0116 21.1318 19.0176 20.9228 18.962 20.726L17.133 14.326L21.669 10.244C21.966 9.97601 22.075 9.55801 21.947 9.17901Z" fill="#FA563B"/></svg>`;

export const not_favorite = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.51603 14.323L5.02603 20.775C4.97989 20.9704 4.99374 21.1751 5.06577 21.3625C5.1378 21.5499 5.26467 21.7112 5.4298 21.8253C5.59492 21.9395 5.79064 22.0012 5.99138 22.0024C6.19212 22.0036 6.38855 21.9442 6.55503 21.832L12 18.202L17.445 21.832C17.6154 21.9451 17.8162 22.0033 18.0207 21.9988C18.2251 21.9944 18.4232 21.9274 18.5884 21.8069C18.7536 21.6865 18.878 21.5183 18.9448 21.3251C19.0116 21.1318 19.0176 20.9228 18.962 20.726L17.133 14.326L21.669 10.244C21.8143 10.1132 21.9181 9.94266 21.9674 9.75351C22.0168 9.56436 22.0097 9.36488 21.9469 9.17974C21.8841 8.9946 21.7685 8.83193 21.6142 8.71183C21.46 8.59173 21.2739 8.51947 21.079 8.504L15.378 8.05L12.911 2.589C12.8324 2.41316 12.7045 2.26384 12.5428 2.15906C12.3812 2.05429 12.1927 1.99854 12 1.99854C11.8074 1.99854 11.6189 2.05429 11.4572 2.15906C11.2956 2.26384 11.1677 2.41316 11.089 2.589L8.62203 8.05L2.92103 8.503C2.72948 8.51818 2.54636 8.58821 2.39358 8.70473C2.2408 8.82125 2.12482 8.97932 2.05952 9.16003C1.99422 9.34074 1.98236 9.53644 2.02537 9.72371C2.06838 9.91098 2.16443 10.0819 2.30203 10.216L6.51603 14.323V14.323ZM9.36903 9.997C9.54752 9.98294 9.71895 9.92112 9.86533 9.81802C10.0117 9.71491 10.1277 9.57433 10.201 9.411L12 5.43L13.799 9.411C13.8724 9.57433 13.9883 9.71491 14.1347 9.81802C14.2811 9.92112 14.4525 9.98294 14.631 9.997L18.603 10.312L15.332 13.256C15.048 13.512 14.935 13.906 15.039 14.274L16.292 18.659L12.556 16.168C12.392 16.0579 12.199 15.9992 12.0015 15.9992C11.804 15.9992 11.611 16.0579 11.447 16.168L7.54303 18.771L8.59303 14.225C8.63153 14.0578 8.62636 13.8835 8.57802 13.7189C8.52969 13.5543 8.43981 13.4048 8.31703 13.285L5.27903 10.323L9.36903 9.997V9.997Z" fill="#FA563B"/></svg>`;

export const calendar = `<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.5 15H11.5V18H13.5V15H16.5V13H13.5V10H11.5V13H8.5V15Z" fill="white"/><path d="M19.5 4H17.5V2H15.5V4H9.5V2H7.5V4H5.5C4.397 4 3.5 4.897 3.5 6V20C3.5 21.103 4.397 22 5.5 22H19.5C20.603 22 21.5 21.103 21.5 20V6C21.5 4.897 20.603 4 19.5 4ZM19.502 20H5.5V8H19.5L19.502 20Z" fill="white"/></svg>`;

export const new_calendar = `<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.75 2.5C15.75 2.08579 15.4142 1.75 15 1.75C14.5858 1.75 14.25 2.08579 14.25 2.5V3.75H10.5C10.0858 3.75 9.75 4.08579 9.75 4.5C9.75 4.91421 10.0858 5.25 10.5 5.25H14.25V6.5C14.25 6.91421 14.5858 7.25 15 7.25C15.4142 7.25 15.75 6.91421 15.75 6.5V2.5ZM2.25 10.5V6.5C2.25 4.98122 3.48122 3.75 5 3.75H6.25V2.5C6.25 2.08579 6.58579 1.75 7 1.75C7.41421 1.75 7.75 2.08579 7.75 2.5V4.5V6.5C7.75 6.91421 7.41421 7.25 7 7.25C6.58579 7.25 6.25 6.91421 6.25 6.5V5.25H5C4.30964 5.25 3.75 5.80964 3.75 6.5V9.75H20.25V6.5C20.25 5.80964 19.6904 5.25 19 5.25H18.5C18.0858 5.25 17.75 4.91421 17.75 4.5C17.75 4.08579 18.0858 3.75 18.5 3.75H19C20.5188 3.75 21.75 4.98122 21.75 6.5V10.5V19.5C21.75 21.0188 20.5188 22.25 19 22.25H5C3.48122 22.25 2.25 21.0188 2.25 19.5V10.5ZM3.75 19.5V11.25H20.25V19.5C20.25 20.1904 19.6904 20.75 19 20.75H5C4.30964 20.75 3.75 20.1904 3.75 19.5Z" fill="#80808A"/></svg>`;

export const photos = `<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.524 11.536L10.5 10L8.5 13H17.5L14 8L11.524 11.536Z" fill="#80808A"/><path d="M10.003 9C10.8331 9 11.506 8.32709 11.506 7.497C11.506 6.66692 10.8331 5.994 10.003 5.994C9.17292 5.994 8.5 6.66692 8.5 7.497C8.5 8.32709 9.17292 9 10.003 9Z" fill="#80808A"/><path d="M19.5 2H6.5C5.294 2 3.5 2.799 3.5 5V19C3.5 21.201 5.294 22 6.5 22H21.5V20H6.512C6.05 19.988 5.5 19.806 5.5 19C5.5 18.194 6.05 18.012 6.512 18H21.5V4C21.5 2.897 20.603 2 19.5 2ZM19.5 16H5.5V5C5.5 4.194 6.05 4.012 6.5 4H19.5V16Z" fill="#80808A"/></svg>`;

export const acceptQuote = `<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.25 12C3.25 6.89137 7.39137 2.75 12.5 2.75C17.6086 2.75 21.75 6.89137 21.75 12C21.75 17.1086 17.6086 21.25 12.5 21.25C7.39137 21.25 3.25 17.1086 3.25 12ZM12.5 1.25C6.56294 1.25 1.75 6.06294 1.75 12C1.75 17.9371 6.56294 22.75 12.5 22.75C18.4371 22.75 23.25 17.9371 23.25 12C23.25 6.06294 18.4371 1.25 12.5 1.25ZM8.03032 11.9697C7.73743 11.6768 7.26255 11.6768 6.96966 11.9697C6.67677 12.2626 6.67677 12.7374 6.96966 13.0303L9.96966 16.0303C10.2626 16.3232 10.7374 16.3232 11.0303 16.0303L18.0303 9.03033C18.3232 8.73744 18.3232 8.26256 18.0303 7.96967C17.7374 7.67678 17.2626 7.67678 16.9697 7.96967L10.5 14.4393L8.03032 11.9697Z" fill="#80808A"/></svg>`;

export const info = `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd"	d="M1.75 11C1.75 5.89137 5.89137 1.75 11 1.75C16.1086 1.75 20.25 5.89137 20.25 11C20.25 16.1086 16.1086 20.25 11 20.25C5.89137 20.25 1.75 16.1086 1.75 11ZM11 0.25C5.06294 0.25 0.25 5.06294 0.25 11C0.25 16.9371 5.06294 21.75 11 21.75C16.9371 21.75 21.75 16.9371 21.75 11C21.75 5.06294 16.9371 0.25 11 0.25ZM11.5117 5.94132C11.8196 6.21841 11.8446 6.69263 11.5675 7.00051L11.5575 7.01162C11.2804 7.3195 10.8062 7.34446 10.4983 7.06737C10.1904 6.79027 10.1654 6.31606 10.4425 6.00817L10.4525 5.99706C10.7296 5.68918 11.2038 5.66422 11.5117 5.94132ZM11 9.75C11.4142 9.75 11.75 10.0858 11.75 10.5V15.5C11.75 15.9142 11.4142 16.25 11 16.25C10.5858 16.25 10.25 15.9142 10.25 15.5V10.5C10.25 10.0858 10.5858 9.75 11 9.75Z"	fill="white" /></svg>`;

export const modal_info_big = `<svg width="80" height="81" viewBox="0 0 80 81" fill="none" xmlns="http://www.w3.org/2000/svg"><rect y="0.5" width="80" height="80" rx="40" fill="#F2F7FD"/><path d="M40 20.5C28.972 20.5 20 29.472 20 40.5C20 51.528 28.972 60.5 40 60.5C51.028 60.5 60 51.528 60 40.5C60 29.472 51.028 20.5 40 20.5ZM42 50.5H38V38.5H42V50.5ZM42 34.5H38V30.5H42V34.5Z" fill="#35A0FF"/></svg>`;

export const modal_warning_big = `<svg width="80" height="81" viewBox="0 0 80 81" fill="none" xmlns="http://www.w3.org/2000/svg"><rect y="0.5" width="80" height="80" rx="40" fill="#FDF1E3"/><path d="M41.768 21.564C41.076 20.256 38.924 20.256 38.232 21.564L20.232 55.564C20.0699 55.8687 19.9896 56.2103 19.9988 56.5553C20.008 56.9003 20.1064 57.237 20.2845 57.5327C20.4625 57.8284 20.7141 58.0729 21.0148 58.2424C21.3154 58.4119 21.6548 58.5007 22 58.5H58C58.3449 58.5007 58.6841 58.4121 58.9845 58.2426C59.285 58.0732 59.5364 57.8288 59.7142 57.5333C59.8921 57.2378 59.9904 56.9013 59.9994 56.5565C60.0085 56.2117 59.9281 55.8705 59.766 55.566L41.768 21.564ZM42 52.5H38V48.5H42V52.5ZM38 44.5V34.5H42L42.002 44.5H38Z" fill="#F3B467"/></svg>`;

export const copy_boxes = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.6 3.25C3.85442 3.25 3.25 3.85442 3.25 4.6V14.4C3.25 15.1456 3.85442 15.75 4.6 15.75H8.25V19.4C8.25 20.1456 8.85442 20.75 9.6 20.75H19.4C20.1456 20.75 20.75 20.1456 20.75 19.4V9.6C20.75 8.85442 20.1456 8.25 19.4 8.25H15.75V4.6C15.75 3.85442 15.1456 3.25 14.4 3.25H4.6ZM14.25 8.25V4.75H4.75V14.25H8.25V9.6C8.25 8.85442 8.85442 8.25 9.6 8.25H14.25ZM9.75 15V19.25H19.25V9.75H15H9.75V15Z" fill="#35A0FF"/></svg>`;
