import { localization } from "@/shared/lib";
import { computed } from "vue";
import {
    applianceSearch,
    inputDate,
    inputSelect,
    inputStandard,
    searchRecord,
    standardCustomButton,
    textArea,
    textHead,
    translucentCustomButton,
    transparentCustomButton,
} from "./defaultInputProps";

export const form_template = [
    {
        component: {
            ...textHead,
            title: localization("records.appliance.property"),
        },
        child: [
            {
                component: {
                    ...searchRecord,
                    title: localization("records.fields.property"),
                    name: "property",
                },
            },
        ],
    },
    {
        component: {
            ...textHead,
            title: localization("records.appliance.appliance"),
        },
        child: [
            {
                component: {
                    ...inputSelect,
                    title: localization("records.fields.appliance_type"),
                    name: "appliancetype",
                    itemsType: "oil_appliancetype",
                    lg: 12,
                },
            },
            {
                component: {
                    ...applianceSearch,
                    title: localization("records.fields.make"),
                    required: true,
                    name: "oilappliancemake",
                    type: "oilmake",
                },
            },
            {
                component: {
                    ...applianceSearch,
                    title: localization("records.fields.model"),
                    name: "oilappliancemodel",
                    type: "oilmodel",
                },
            },
            {
                component: {
                    ...applianceSearch,
                    title: localization("records.fields.location"),
                    name: "oilappliancelocation",
                    type: "oillocation",
                },
            },
            {
                component: {
                    ...inputStandard,
                    title: localization("records.fields.serial_number"),
                    name: "serialnumber",
                },
            },
        ],
    },
    {
        component: {
            ...textHead,
            title: localization("records.appliance.boiler"),
        },
        child: [
            {
                component: {
                    ...inputSelect,
                    title: localization("records.fields.boiler_type"),
                    name: "boilertype",
                    itemsType: "oil_boilertype",
                    lg: 12,
                },
            },
        ],
    },
    {
        component: {
            ...textHead,
            title: localization("records.appliance.burner"),
        },
        child: [
            {
                component: {
                    ...inputSelect,
                    title: localization("records.fields.burner_type"),
                    name: "burnertype",
                    itemsType: "oil_burnertype",
                    lg: 12,
                },
            },
            {
                component: {
                    ...applianceSearch,
                    title: localization("records.fields.make"),
                    name: "burnermake",
                    type: "oilburnersmake",
                },
            },
            {
                component: {
                    ...applianceSearch,
                    title: localization("records.fields.model"),
                    name: "burnermodel",
                    type: "oilburnersmodel",
                },
            },
            {
                component: {
                    ...applianceSearch,
                    title: localization("records.fields.serial_number"),
                    name: "burner_serial_number",
                },
            },
            {
                component: {
                    ...applianceSearch,
                    title: localization("records.fields.burner_code"),
                    name: "burner_code",
                },
            },
        ],
    },
    {
        component: {
            ...textHead,
            title: localization("records.appliance.tank"),
        },
        child: [
            {
                component: {
                    ...inputSelect,
                    title: localization("records.fields.tank_type"),
                    validation: true,
                    name: "tanktype",
                    itemsType: "oil_tanktype",
                    lg: 12,
                },
            },
            {
                component: {
                    ...inputSelect,
                    title: localization("records.fields.flue_type"),
                    name: "oilappliancefluetype",
                    itemsType: "oil_fluetype",
                },
            },
            {
                component: {
                    ...inputSelect,
                    title: localization("records.fields.fuel_type"),
                    name: "oilappliancefueltype",
                    itemsType: "oil_fueltype",
                },
            },
        ],
    },
    {
        component: {
            ...textHead,
            title: localization("records.appliance.more_info"),
        },
        child: [
            {
                component: {
                    ...inputSelect,
                    title: localization("records.fields.equipment_owner"),
                    validation: true,
                    name: "owner",
                    itemsType: "oil_owner",
                },
            },
            {
                component: {
                    ...inputStandard,
                    title: localization("records.fields.manufacturers_efficiency"),
                    name: "sedbukefficiency",
                },
            },
            {
                component: {
                    ...inputSelect,
                    title: localization("records.fields.service_period"),
                    name: "serviceperiod",
                    itemsType: "oil_serviceperiod",
                },
            },
            {
                component: {
                    ...inputDate,
                    title: localization("records.fields.service_due"),
                    name: "servicedue",
                },
            },
            {
                component: {
                    ...inputDate,
                    title: localization("records.fields.installation_date"),
                    name: "installation_date",
                },
            },
            {
                component: {
                    ...inputStandard,
                    title: localization("records.fields.age"),
                    name: "age",
                },
            },
        ],
    },
    {
        component: {
            ...textHead,
            title: localization("records.fields.notes"),
        },
        child: [
            {
                component: {
                    ...textArea,
                    name: "notes",
                },
            },
        ],
    },
];

const create_template = [
    {
        component: {
            componentName: "div",
            content: "flex-end",
            gap: 16,
            wrap: false,
        },
        child: [
            {
                component: {
                    ...standardCustomButton,
                    text: localization("buttons.cancel"),
                    action: "cancel",
                    wide: false,
                },
            },
            {
                component: {
                    componentName: "div",
                    class: "divider",
                },
            },
            {
                component: {
                    ...transparentCustomButton,
                    text: localization("buttons.create"),
                    action: "create",
                    wide: false,
                },
            },
        ],
    },
];
const edit_template = computed({
    get() {
        return (data) => [
            {
                component: {
                    componentName: "div",
                    content: "space-between",
                    gap: 16,
                    wrap: false,
                },
                child: [
                    {
                        component: {
                            ...translucentCustomButton,
                            text: localization(`buttons.${+data.archive ? "unarchive" : "delete"}`),
                            action: { key: "delete", value: { archive: data.archive } },
                            wide: false,
                        },
                    },
                ],
            },
            {
                component: {
                    componentName: "div",
                    content: "flex-end",
                },
                child: [
                    {
                        component: {
                            ...standardCustomButton,
                            text: localization("buttons.cancel"),
                            action: "cancel",
                            wide: false,
                        },
                    },
                    {
                        component: {
                            componentName: "div",
                            class: "divider",
                        },
                    },
                    {
                        component: {
                            ...transparentCustomButton,
                            text: localization("buttons.save"),
                            action: "save",
                            wide: false,
                        },
                    },
                ],
            },
        ];
    },
});

export const aside_template = {
    create: create_template,
    edit: edit_template.value,
};

export const archive_checkboxes = [];

export const type = "oil_appliance";
