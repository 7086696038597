import { localization } from "@/shared/lib";
import { computed } from "vue";
import {
    applianceSearch,
    inputDate,
    inputSelect,
    inputStandard,
    searchRecord,
    standardCustomButton,
    textArea,
    textHead,
    translucentCustomButton,
    transparentCustomButton,
} from "./defaultInputProps";

export const form_template = [
    {
        component: {
            title: localization("records.appliance.property"),
            ...textHead,
        },
        child: [
            {
                component: {
                    ...searchRecord,
                    title: localization("records.fields.property"),
                    name: "property",
                    type: "property",
                },
            },
        ],
    },
    {
        component: {
            ...textHead,
            title: localization("records.appliance.appliance"),
        },
        child: [
            {
                component: {
                    ...applianceSearch,
                    title: localization("records.fields.type"),
                    name: "appliancetype",
                    type: "type",
                    required: true,
                    lg: 12,
                },
            },
            {
                component: {
                    title: localization("records.fields.make"),
                    name: "appliancemake",
                    type: "make",
                    ...applianceSearch,
                },
            },
            {
                component: {
                    ...applianceSearch,
                    title: localization("records.fields.model"),
                    name: "appliancemodel",
                    type: "model",
                },
            },
            {
                component: {
                    ...applianceSearch,
                    title: localization("records.fields.location"),
                    name: "appliancelocation",
                    type: "location",
                },
            },
        ],
    },
    {
        component: {
            ...textHead,
            title: localization("records.appliance.flue"),
        },
        child: [
            {
                component: {
                    ...inputSelect,
                    title: localization("records.fields.flue_type"),
                    name: "appliancefluetype",
                    lg: 12,
                    itemsType: "gas_fluetype",
                },
            },
        ],
    },
    {
        component: {
            ...textHead,
            title: localization("records.appliance.more_info"),
        },
        child: [
            {
                component: {
                    ...inputSelect,
                    title: localization("records.fields.landlord_equipment"),
                    name: "landlordequipment",
                    itemsType: "gas_landlord_equipment",
                },
            },
            {
                component: {
                    ...inputDate,
                    title: localization("records.fields.installation_date"),
                    name: "installation_date",
                },
            },
            {
                component: {
                    ...inputStandard,
                    title: localization("records.fields.serial_number"),
                    name: "serialnumber",
                },
            },
            {
                component: {
                    ...inputStandard,
                    title: localization("records.fields.gas_council_number"),
                    name: "gcn",
                },
            },
        ],
    },
    {
        component: {
            ...textHead,
            title: localization("records.fields.notes"),
        },
        child: [
            {
                component: {
                    ...textArea,
                    name: "notes",
                },
            },
        ],
    },
];

const create_template = [
    {
        component: {
            componentName: "div",
            content: "flex-end",
            gap: 16,
            wrap: false,
        },
        child: [
            {
                component: {
                    ...standardCustomButton,
                    text: localization("buttons.cancel"),
                    action: "cancel",
                    wide: false,
                },
            },
            {
                component: {
                    componentName: "div",
                    class: "divider",
                },
            },
            {
                component: {
                    ...transparentCustomButton,
                    text: localization("buttons.create"),
                    action: "create",
                    wide: false,
                },
            },
        ],
    },
];

const edit_template = computed({
    get() {
        return (data) => [
            {
                component: {
                    componentName: "div",
                    class: "ml-a",
                    content: "space-between",
                    gap: 16,
                    wrap: false,
                },
                child: [
                    {
                        component: {
                            ...translucentCustomButton,
                            text: localization(`buttons.${+data.archive ? "unarchive" : "delete"}`),
                            action: { key: "delete", value: { archive: data.archive } },
                        },
                    },
                ],
            },
            {
                component: {
                    componentName: "div",
                    content: "flex-end",
                },
                child: [
                    {
                        component: {
                            ...standardCustomButton,
                            text: localization("buttons.cancel"),
                            action: "cancel",
                            wide: false,
                        },
                    },
                    {
                        component: {
                            componentName: "div",
                            class: "divider",
                        },
                    },
                    {
                        component: {
                            ...transparentCustomButton,
                            text: localization("buttons.save"),
                            action: "save",
                            wide: false,
                        },
                    },
                ],
            },
        ];
    },
});

export const aside_template = {
    create: create_template,
    edit: edit_template.value,
};

export const archive_checkboxes = [];

export const type = "gas_appliance";
